import LoadingRadial from "../../../components/base/LoadingRadial";
import getSwimlane from "./getSwimlane";
import { useFlagsProviderABTest } from "../../../custom-hooks/useFlagsPoviderABTest";
import useIdentifyAnalyticsUser from "../../../custom-hooks/useIdentifyAnalyticsUser";
import useInitialiseFlagsProvider from "../../../custom-hooks/useInitialiseFlagsProvider";

const urlParams = new URLSearchParams(window.location.search);
const isExperimentUser: boolean = urlParams.get("exu") === "true";
const isRiskyExperimentUser: boolean = urlParams.get("rexu") === "true";
const disableUpFrontPayment: boolean = urlParams.get("dufp") === "true";

type ExperimentsProviderProps = {
  generalSettings: Record<string, string>;
  children: React.ReactNode;
};

const ExperimentsProvider = ({
  children,
  generalSettings,
}: ExperimentsProviderProps) => {
  const swimlane = generalSettings["GROWTH_EXPERIMENT_SWIMLANES"]
    ? getSwimlane(generalSettings["GROWTH_EXPERIMENT_SWIMLANES"])
    : undefined;

  useIdentifyAnalyticsUser({
    isExperimentUser,
    isRiskyExperimentUser,
    swimlane,
  });

  const flagsProviderReady = useInitialiseFlagsProvider({
    isExperimentUser,
    isRiskyExperimentUser,
    disableUpFrontPayment,
    swimlane,
  });

  useFlagsProviderABTest();

  if (!flagsProviderReady) {
    return <LoadingRadial />;
  }

  return <>{children}</>;
};

export default ExperimentsProvider;
