import useFetchGeoLocation from "./useFetchGeoLocation.ts";

export default function useGdprApplies(): boolean {
  const geoData = useFetchGeoLocation();

  if (geoData.data != null && geoData.data.meta != null) {
    return geoData.data.meta.isGdpr || false;
  }

  // Defaulting to the strictest setting of GDPR applying
  return true;
}
