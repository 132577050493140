import { RootState } from "../reducers";
import { UserEmailSource } from "../reducers/user.ts";

export function selectUserToken(state: RootState): string | null {
  return state.user.token;
}

export function selectUserEmail(state: RootState): string | undefined {
  return state.user.email;
}

export function selectUserEmailSource(
  state: RootState,
): UserEmailSource | undefined {
  return state.user.emailSource;
}

export function selectUserFirstName(state: RootState): string | undefined {
  return state.user.firstName;
}
