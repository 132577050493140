import { QUIZ_EMAIL_ENTERED, USER_EMAIL_CHANGED } from "../constants/actions";

import { selectAnalyticsUuid } from "../selectors/analytics";
import storeCustomerEmail from "../adhdQuiz/utils/storeCustomerEmail";
import { useAppDispatch } from "../store";
import { useEffect } from "react";
import useGdprApplies from "./useGdprApplies";
import { useSelector } from "react-redux";

/*
This stores the user's email in the redux store if it is present in the URL parameters as "userEmail".

To cater for a + being in the email addresss, the email MUST be url encoded.

On CIO, this can be done using 
?userEmail={{customer.email | url_encode}}

*/

export default function useUserEmailFromParam() {
  const dispatch = useAppDispatch();
  const urlParams = new URLSearchParams(window.location.search);
  const userEmail = urlParams.get("userEmail");
  const isGDPR = useGdprApplies();
  const analyticsUuid = useSelector(selectAnalyticsUuid);

  useEffect(() => {
    const storedEmail = window.localStorage.getItem("userEmail");
    if (userEmail && userEmail !== storedEmail) {
      window.localStorage.setItem("userEmail", decodeURIComponent(userEmail));
      storeCustomerEmail(
        analyticsUuid,
        decodeURIComponent(userEmail),
        true,
        isGDPR,
      );
      dispatch({
        type: USER_EMAIL_CHANGED,
        email: decodeURIComponent(userEmail),
      });
      dispatch({
        type: QUIZ_EMAIL_ENTERED,
      });
    }
  }, [analyticsUuid, userEmail, isGDPR, dispatch]);
}
