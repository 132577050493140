import { AppRouter } from "./routers/AppRouter";
import ExperimentsProvider from "./checkout/components/ExperimentsProvider/ExperimentsProvider.tsx";
import LoadingRadial from "./components/base/LoadingRadial.tsx";
import { QUIZ_ERROR } from "./constants/actions.ts";
import { QuizErrorHandler } from "./adhdQuiz/components/QuizErrorHandler/QuizErrorHandler.tsx";
import QuizStateProvider from "./adhdQuiz/QuizStateProvider/QuizStateProvider.tsx";
import { RouterProvider } from "react-router-dom";
import { SpeedInsights } from "@vercel/speed-insights/react";
import { logQuizError } from "./utils/logQuizError.ts";
import { selectAnalyticsUuid } from "./selectors/analytics.ts";
import { selectQuizErrors } from "./selectors/quiz-error.ts";
import { useAppDispatch } from "./store.ts";
import { useEffect } from "react";
import useFirstQuizLoad from "./custom-hooks/useFirstQuizLoad.ts";
import useRemoteConfig from "./custom-hooks/useRemoteConfig";
import { useSelector } from "react-redux";
import useUserData from "./custom-hooks/useUserData";
import useUserEmailFromParam from "./custom-hooks/useUserEmailFromParam.ts";
import utils from "./utils";

const getToken = () => window.localStorage.getItem("token");

const urlParams = new URLSearchParams(window.location.search);
const tokenQuery = urlParams.get("token");
const fromApp = urlParams.get("fromApp");
const studentDiscountCode = urlParams.get("sdc");
const debug = urlParams.get("debug") || localStorage.getItem("debug");

const token = getToken();
if (fromApp) {
  window.localStorage.setItem("fromApp", fromApp);
}
if (studentDiscountCode === "1") {
  window.localStorage.setItem("showCouponInput", "true");
}
if (!token && tokenQuery) {
  window.localStorage.setItem("token", utils.decodeToken(tokenQuery));
  // and redirect to login
  window.location.href = "/login";
}

// test parameters for playwright tests
const testFlags = urlParams.get("testFlags");
const testAnswers = urlParams.get("testAnswers");
const testSwimlane = urlParams.get("testSwimlane");

if (testFlags) {
  window.localStorage.setItem("testFlags", testFlags);
}
if (testAnswers) {
  window.localStorage.setItem("testAnswers", testAnswers);
}
if (testSwimlane) {
  window.localStorage.setItem("testSwimlane", testSwimlane);
}

export default function App() {
  const { data } = useRemoteConfig();
  const { isLoading, isLoggedIn } = useUserData();
  const dispatch = useAppDispatch();
  const analyticsUuid = useSelector(selectAnalyticsUuid);
  const showQuizError = debug === "true"; // || project.customEnv !== "production"

  useFirstQuizLoad();

  // store email from userEmail
  useUserEmailFromParam();

  useEffect(() => {
    const errorHandler = (event: any) => {
      const error = {
        message: event.message,
        page: window.location.pathname,
      };
      dispatch({
        type: QUIZ_ERROR,
        error,
      });
      logQuizError(error, analyticsUuid);
    };

    const rejectionHandler = (event: PromiseRejectionEvent) => {
      const error = {
        message: event.reason.message,
        page: window.location.pathname,
      };
      dispatch({
        type: QUIZ_ERROR,
        error,
      });
      logQuizError(error, analyticsUuid);
    };

    window.addEventListener("error", errorHandler);
    window.addEventListener("unhandledrejection", rejectionHandler);
    return () => {
      window.removeEventListener("error", errorHandler);
      window.removeEventListener("unhandledrejection", rejectionHandler);
    };
  }, [analyticsUuid, dispatch]);

  useEffect(() => {
    localStorage.setItem("debug", debug || "");
  }, []);

  const quizErrors = useSelector(selectQuizErrors);

  const appInitialising = data == null || (!isLoggedIn && isLoading);

  if (quizErrors.length && showQuizError) {
    return <QuizErrorHandler errors={quizErrors} />;
  }
  return appInitialising ? (
    <LoadingRadial />
  ) : (
    <ExperimentsProvider generalSettings={data.generalSettings}>
      <QuizStateProvider>
        <SpeedInsights />
        <RouterProvider router={AppRouter()} />
      </QuizStateProvider>
    </ExperimentsProvider>
  );
}
