import { cioIdentifyWithEmail } from "../../services/CustomerIO.ts";
import { gtmTrackLeadEvent } from "../../checkout/pages/CheckoutPaymentPage/utils/GTMEvents.ts";
import { trackUserLeadEvent } from "../../services/Api.ts";

export default function storeCustomerEmail(
  analyticsUuid: string,
  email: string,
  emailConsent?: boolean,
  isGDPR?: boolean,
) {
  cioIdentifyWithEmail(analyticsUuid, email);
  gtmTrackLeadEvent(analyticsUuid, email); // client-side
  trackUserLeadEvent(analyticsUuid, email, "quiz", {
    ...(emailConsent != null && { emailConsent: emailConsent ? "yes" : "no" }),
    ...(isGDPR != null && { isGDPR: isGDPR ? "yes" : "no" }),
  }); // server-side
}
