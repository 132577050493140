import anonymousUuidStorage from "./anonymousUuidStorage";
import isBase64 from "./isBase64";
import isEmail from "./isEmail";
import { jwtDecode } from "jwt-decode";
import tokenStorage from "./tokenStorage";

const getToken = () => window.localStorage.getItem("token");

const decodeToken = (token: string) =>
  Buffer.from(token, "base64").toString("ascii");

const getValFromToken = (token: string, key: string): string | undefined => {
  const decoded: Record<string, string> = jwtDecode(token);

  if (decoded == null || typeof decoded === "string") {
    return undefined;
  }

  return decoded[key];
};

const percentageOff = (price: number, percentageValue: number): number => {
  return price * (1 - percentageValue / 100);
};

function Dec2(n: number) {
  const num = String(n);
  if (num.indexOf(".") !== -1) {
    const numarr = num.split(".");
    if (numarr.length === 1) {
      return Number(num);
    } else {
      return Number(
        numarr[0] + "." + numarr[1].charAt(0) + numarr[1].charAt(1),
      );
    }
  } else {
    return Number(num);
  }
}

const isUserOnApp = () => !!window.ReactNativeWebView;

const logOut = async () => {
  // removed the toast here: never going to fire
  window.localStorage.clear();
  window.location.href = "https://getinflow.io";
};

export default {
  anonymousUuidStorage,
  tokenStorage,
  isBase64,
  isEmail,
  getToken,
  percentageOff,
  Dec2,
  decodeToken,
  isUserOnApp,
  logOut,
  getValFromToken,
};
