import { NavigateOptions, useLocation, useNavigate } from "react-router-dom";

import { useMemo } from "react";

export function useHistory() {
  const navigate = useNavigate();
  const location = useLocation();

  return useMemo(() => {
    const _navigate = (to: any, options?: NavigateOptions) => {
      // breakpoint here to catch navigation calls
      // console.log("navigate: ", to);
      navigate(to, options);
    };

    return {
      push: _navigate,
      go: _navigate,
      goBack: () => _navigate(-1),
      goForward: () => _navigate(1),
      replace: (path: string) => _navigate(path, { replace: true }),
      listen: null, // implement the hook yourself
      location,
    };
  }, [location, navigate]);
}
