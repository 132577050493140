export async function gtmTrackLeadEvent(userUuid: string, email: string) {
  if (!window.dataLayer) return;

  // clear the previous ecommerce object
  window.dataLayer.push({ ecommerce: null });

  window.dataLayer.push({
    event: "email",
    ecommerce: {
      user_data: {
        email_address: email,
        external_id: userUuid,
      },
    },
  });
}

export async function gtmTrackPurchaseAndStartTrialEvents(
  userUuid: string,
  subscriptionId: string | undefined,
  predictedLtv: number | undefined,
) {
  if (!window.dataLayer) return;

  const p = new URLSearchParams(window.location.search);
  const email = p.get("email") || "";
  const unitAmount = (parseInt(p.get("priceUnitAmount") || "", 10) || 0) / 100;
  const itemName = "Subscription " + p.get("selectedPriceId");
  const currency = p.get("priceCurrency") || undefined;
  const productId = p.get("selectedPriceId"); // id of the product in stripe
  const coupon = p.get("couponCode") || p.get("coupon") || undefined;
  const transactionId = Date.now();

  // clear the previous ecommerce object
  window.dataLayer.push({ ecommerce: null });

  // create a new Purchase object with timestamp as the id and data
  // also trigger StartTrial in GTM
  window.dataLayer.push({
    event: "purchase",
    ecommerce: {
      ...(subscriptionId ? { subscription_id: subscriptionId } : {}),
      ...(predictedLtv ? { predicted_ltv: predictedLtv } : {}),
      transaction_id: transactionId,
      value: unitAmount,
      tax: null,
      shipping: null,
      currency: currency || undefined,
      coupon: coupon,
      items: [
        {
          item_id: productId, // id of the product in stripe
          item_name: itemName,
          price: unitAmount,
          discount: 0,
          quantity: 1,
        },
      ],
      user_data: {
        email_address: email,
        external_id: userUuid,
      },
    },
  });
}
