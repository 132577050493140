interface AccountIconProps {
  id?: string;
  iconName:
    | "Download"
    | "Manage"
    | "Help"
    | "Chevron"
    | "CreditCard"
    | "Cancel";
}

const AccountIcon = ({ iconName, id }: AccountIconProps) => {
  switch (iconName) {
    case "Download":
      return (
        <svg
          id={id}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 14 14"
          fill="none"
        >
          <g clipPath="url(#clip0_6288_2570)">
            <path
              d="M1.18288 10.3586H12.8176C12.9953 10.3586 13.1391 10.5024 13.1391 10.6801V13.5387C13.1391 13.7164 12.9953 13.8602 12.8176 13.8602H1.18288C1.00515 13.8602 0.861328 13.7164 0.861328 13.5387V10.6801C0.861328 10.5024 1.00515 10.3586 1.18288 10.3586ZM11.4363 3.54675L7.26029 9.09367C7.24169 9.11937 7.21927 9.14289 7.19247 9.16312C7.05083 9.26921 6.85013 9.24023 6.74403 9.09859L2.5823 3.56917C2.53089 3.5123 2.49972 3.43683 2.49972 3.3537C2.49972 3.17597 2.64354 3.03215 2.82127 3.03215H4.91206V0.460229C4.91206 0.282499 5.05588 0.138672 5.23361 0.138672H8.76745C8.94518 0.138672 9.089 0.282499 9.089 0.460229V3.03215H11.1798V3.03324C11.2465 3.03324 11.3143 3.05402 11.3717 3.09777C11.5134 3.20386 11.5418 3.40511 11.4363 3.54675Z"
              fill="black"
            />
          </g>
          <defs>
            <clipPath id="clip0_6288_2570">
              <rect width="14" height="14" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    case "Manage":
      return (
        <svg
          id={id}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.36133 15.3232C1.36133 13.4901 2.10445 11.8294 3.30626 10.6281C4.50751 9.42685 6.1676 8.68317 8.00133 8.68317C9.83506 8.68317 11.4951 9.42629 12.6964 10.6281C13.8976 11.8294 14.6413 13.4894 14.6413 15.3232C14.6413 15.525 14.4776 15.6894 14.2751 15.6894H1.72814C1.52626 15.6894 1.36189 15.525 1.36189 15.3232H1.36133ZM8.00066 0.3125C9.05004 0.3125 10 0.7375 10.6876 1.42562C11.3751 2.11311 11.8007 3.06309 11.8007 4.11255C11.8007 5.16193 11.3757 6.11189 10.6876 6.79949C9.99948 7.48761 9.05013 7.91261 8.00066 7.91261C6.9512 7.91261 6.00133 7.48761 5.31373 6.79949C4.62624 6.11199 4.20061 5.16202 4.20061 4.11255C4.20061 3.06381 4.62561 2.11322 5.31373 1.42562C6.00185 0.7375 6.9512 0.3125 8.00066 0.3125Z"
            fill="black"
          />
        </svg>
      );
    case "Help":
      return (
        <svg
          id={id}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 18 18"
          fill="none"
        >
          <g clipPath="url(#clip0_6288_2587)">
            <path
              d="M9.00023 0.990234C6.87593 0.990234 4.83848 1.83416 3.33638 3.33638C1.83428 4.8386 0.990234 6.87593 0.990234 9.00023C0.990234 11.1245 1.83416 13.162 3.33638 14.6641C4.8386 16.1662 6.87593 17.0102 9.00023 17.0102C11.1245 17.0102 13.162 16.1663 14.6641 14.6641C16.1662 13.1619 17.0102 11.1245 17.0102 9.00023C17.0102 6.87593 16.1663 4.83848 14.6641 3.33638C13.1619 1.83428 11.1245 0.990234 9.00023 0.990234ZM9.53215 13.7074C9.32215 13.9043 9.04348 14.0114 8.7556 14.0056C8.46838 14.012 8.19028 13.9048 7.98163 13.7074C7.77907 13.5245 7.66322 13.2645 7.66264 12.9916C7.66199 12.7187 7.77666 12.4582 7.97834 12.2744C8.18425 12.0793 8.45869 11.9731 8.74229 11.9788C9.03251 11.9726 9.31399 12.0785 9.52816 12.2744C9.73675 12.4531 9.85582 12.7147 9.85359 12.9894C9.85599 13.2641 9.73864 13.5262 9.53215 13.7074ZM11.7164 7.86054C11.6243 8.10785 11.497 8.34065 11.3385 8.55176C11.1187 8.8063 10.8756 9.03973 10.6124 9.24909C10.3625 9.4462 10.1321 9.6668 9.92443 9.90786C9.81855 10.0641 9.73951 10.2369 9.6904 10.4191C9.61616 10.7088 9.57597 11.0063 9.57052 11.3054H7.85917L7.85284 11.0395C7.8461 10.5427 7.9518 10.0508 8.16209 9.60058C8.40292 9.12673 8.74568 8.71207 9.16574 8.38628C9.46211 8.16469 9.73316 7.91114 9.97417 7.63031C10.0684 7.48664 10.1168 7.31771 10.1129 7.14592C10.111 6.88981 9.98477 6.65062 9.77437 6.50461C9.51064 6.31735 9.19142 6.22459 8.8684 6.24146C8.52182 6.22723 8.18215 6.34125 7.91443 6.56185C7.67068 6.76628 7.53181 7.06957 7.53638 7.38767H5.88218H5.88224C5.85787 6.6665 6.16525 5.97392 6.71632 5.50817C7.28035 5.01283 8.02319 4.76521 8.94487 4.76533H8.94493C9.46694 4.75045 9.98537 4.85556 10.4604 5.07259C10.869 5.27017 11.2152 5.57662 11.461 5.95817C11.7034 6.31805 11.834 6.74146 11.8367 7.17535C11.8363 7.40896 11.7957 7.64081 11.7166 7.86061L11.7164 7.86054Z"
              fill="black"
            />
          </g>
          <defs>
            <clipPath id="clip0_6288_2587">
              <rect width="18" height="18" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    case "Chevron":
      return (
        <svg
          id={id}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 8 14"
          fill="none"
        >
          <path
            d="M1 1L7 7L1 13"
            stroke="#6C6C6C"
            strokeWidth="1.875"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case "CreditCard":
      return (
        <svg
          id={id}
          viewBox="0 0 14 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 4.94612V8.52218C0 8.89922 0.154172 9.24218 0.402173 9.49018C0.650734 9.73818 0.993146 9.89235 1.37018 9.89235H12.6298C13.0069 9.89235 13.3498 9.73818 13.5978 9.49018C13.8458 9.24162 14 8.89921 14 8.52218V1.37018C14 0.993135 13.8458 0.650173 13.5978 0.402173C13.3493 0.154172 13.0069 0 12.6298 0H1.37018C0.992575 0 0.650173 0.154172 0.402173 0.402173C0.153612 0.650733 0 0.993146 0 1.37018V4.94612ZM13.3438 4.61768H0.655393V2.98492H13.3438V4.61768ZM6.52986 7.96138C6.34887 7.96138 6.20142 7.81447 6.20142 7.63294C6.20142 7.45196 6.34832 7.30451 6.52986 7.30451H8.88486C9.06585 7.30451 9.2133 7.45141 9.2133 7.63294C9.2133 7.81393 9.06639 7.96138 8.88486 7.96138H6.52986ZM10.3616 7.96138C10.1807 7.96138 10.0332 7.81447 10.0332 7.63294C10.0332 7.45196 10.1801 7.30451 10.3616 7.30451H11.5162C11.6972 7.30451 11.8447 7.45141 11.8447 7.63294C11.8447 7.81393 11.6977 7.96138 11.5162 7.96138H10.3616Z"
            fill="black"
          />
        </svg>
      );
    case "Cancel":
      return (
        <svg
          id={id}
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_6288_2628)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.29178 0.140625H9.70757C9.7978 0.140625 9.87929 0.177813 9.9378 0.237422L13.7642 4.06386C13.8271 4.12675 13.8583 4.20878 13.8583 4.29081V9.70659C13.8583 9.79682 13.8211 9.87831 13.7615 9.93682L9.93506 13.7633C9.87272 13.8261 9.79014 13.8573 9.70811 13.8573H4.29233C4.20209 13.8573 4.12061 13.8201 4.0621 13.7605L0.235664 9.93408C0.172774 9.87174 0.141602 9.78916 0.141602 9.70713V4.29135C0.141602 4.20112 0.178789 4.11963 0.238399 4.06112L4.06483 0.234687C4.12772 0.171797 4.20976 0.140625 4.29178 0.140625ZM7.53908 6.81361L9.69987 8.97439C9.82565 9.10017 9.82565 9.30306 9.69987 9.42884C9.57409 9.55462 9.37119 9.55462 9.24487 9.42884L7.08408 7.26806L4.9233 9.42884C4.79752 9.55462 4.59463 9.55462 4.46885 9.42884C4.34307 9.30306 4.34307 9.10017 4.46885 8.97439L6.62963 6.81361L4.46885 4.65282C4.34307 4.52705 4.34307 4.32415 4.46885 4.19837C4.59463 4.07259 4.79752 4.07259 4.9233 4.19837L7.08408 6.35916L9.24487 4.19837C9.37065 4.07259 9.57409 4.07259 9.69987 4.19837C9.82565 4.32415 9.82565 4.52705 9.69987 4.65282L7.53908 6.81361Z"
              fill="black"
            />
          </g>
          <defs>
            <clipPath id="clip0_6288_2628">
              <rect width="14" height="14" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    default:
      return <></>;
  }
};

export default AccountIcon;
