import * as Sentry from "@sentry/react";

import { IFlags, IFlagsmithFeature } from "flagsmith/types";
import {
  cioTrackAnalytics,
  cioUpdateUserAttributes,
} from "../services/CustomerIO";

import Mixpanel from "mixpanel-browser";
import { PriceOption } from "./priceFormatUtils";
import { logTrace } from "./logs";
import project from "../project";
import { trackUserBiEvent } from "../services/Api";

const disableAnalytics =
  project.customEnv === "development" ||
  window.localStorage.getItem("e2eTestUser");

export const GTag: Gtag.Gtag =
  project.customEnv !== "development"
    ? window.gtag
    : (...args: any) => {
        console.log("GTAG -----", args);
      };

export const initAnalytics = () => {
  const { mixpanelKey } = project;
  if (mixpanelKey && !disableAnalytics) {
    Mixpanel.init(mixpanelKey, {
      api_host: "https://mix.getinflow.io",
      debug: true,
    });
  }
};

export type OperatingSystemType = "ios" | "android" | "other";

// Each section exists to enforce required properties for
// each property group. For example if you are reporting
// analytics on a module you must provide both a name and id.
// Before being sent to mixpanel this object is flattened
// into a single level object so all keys in each group must
// be globally unique within the scope of the interface or
// mutually exclusive for all events.
export interface AnalyticsEventProperties {
  // TODO interface can't be empty for flatAdditionalProperties function
  share?: {
    url?: string;
    shareHash?: string;
    isOwnResults?: boolean;
    fromNotificationCenter?: boolean;
  };
  tapInfo?: {
    direction: "left" | "right";
    contentIndex?: number;
    tapInstructionVisible?: boolean;
  };
  liveEvent?: {
    eventId: string;
    eventName: string;
    eventStartTime: string;
    eventClickTime?: string;
    faqQuestion?: string;
    urlSource?: string;
  };
  insight?: {
    question: string;
    option?: string;
    enteredText?: string;
  };
  "carousel-page"?: string;
  page?: string;
  pageVersion?: string;
  errorMessage?: string;
  errorPage?: string;
  symptom?: string;
  module?: {
    moduleName: string;
    moduleId: number;
    moduleGroup?: string;
    moduleCategory?: string;
  };
  onboardingAnswers?: {
    ageId?: string;
    adhdDiagnosisId?: string;
    referralId?: string;
    personalizationOptions?: string;
  };
  appliedCoupon?: {
    couponCode: string;
    priceId: string | null | undefined;
    percentOff: number;
  };
  registerSuccess?: {
    method: "google" | "apple" | "email";
    organizationReferralCode: string | null;
  };
  utmData?: {
    utmSource: string | null;
    utmMedium: string | null;
    utmCampaign: string | null;
    utmContent: string | null;
  };
  questionAnswered?: Partial<{
    question_id: string;
    answer_id?: string | string[];
    question_text: string;
    answer_text?: string | string[];
    page?: string;
  }>;
  path?: string;
  quiz_id?: string;
  priceOption?: PriceOption;
  quizQuestion?: {
    key: string;
    answer: string | number | boolean | string[];
  };
  email?: string;
  experiment?: string;
  variant?: string;
  resultsSection?: string;
  payment?: {
    price_id?: string;
    product_id?: string;
    confirmation_type?: string;
    method?: string;
    attempts?: number;
    page_version?: string;
  };
  calculations?: {
    understandAdhdPercentage?: number;
    fourWeekPercentage?: number;
    adhdResultsScore?: number;
    rejectionSensitiveDysphoriaScore?: number;
    emotionalHyperarousalScore?: number;
    inattentiveScore?: number;
    workingMemoryIssuesScore?: number;
    percentageSuccessful?: number;
    timeManagementScore?: number;
    // V2
    productivity?: number;
    relationships?: number;
    mentalHealth?: number;
    emotionalDysregulation?: number;
    selfControlChallenges?: number;
    executiveDysfunction?: number;
  };
  subscriptionFailure?: string;
  paymentMethod?: string;
  charactersChanged?: number;
  experimentId?: string;
  variationId?: string;
  name?: string;
  os?: OperatingSystemType;
  // HCP Details
  providerName?: string;
  providerEmail?: string;
}

/**
 * Flattens an object, nested objects will be keyed by their parent's key in dot-notation.
 * If the object contains an array, the arrray will not be flattened.
 *
 * @param obj The object to flatten
 * @param parentKey The parent key to use for all nested objects
 */
function flattenEventProperties(
  obj?: Record<string, any> | null,
  parentKey?: string,
): Record<string, any> | null | undefined {
  if (!obj || typeof obj !== "object") {
    return undefined;
  }

  let result: Record<string, any> = {};

  Object.entries(obj).forEach(([key, value]) => {
    const _key = parentKey ? parentKey + "." + key : key;
    if (!value) {
      result[_key] = "";
    } else if (typeof value === "object" && !Array.isArray(value)) {
      result = { ...result, ...flattenEventProperties(value, _key) };
    } else {
      result[_key] = value;
    }
  });

  return result;
}

export const setUserAnalyticsProperties = (
  userProps: Record<string, boolean | string | number>,
) => {
  if (project.customEnv !== "development") {
    Mixpanel.people.set(userProps);
  }
};

// update user props and send Flags Changed event
export const flagsChangedEvent = (analyticsUuid: string, allFlags: IFlags) => {
  let userFlags: Record<string, boolean | string | number> = {};
  for (const key in allFlags) {
    const feature = convertFeatureToAnalyticsProperties(key, allFlags[key]);
    userFlags = {
      ...userFlags,
      ...feature,
    };
  }
  try {
    Mixpanel.track("Flags Changed", userFlags);
    setUserAnalyticsProperties(userFlags);
    if (analyticsUuid) cioUpdateUserAttributes(analyticsUuid, userFlags);
    cioTrackAnalytics("Flags Changed", userFlags);
  } catch (e: any) {
    console.warn("flags-error", e);
  }
};

// calls server side analytics only
// - used in some SSA specific tickets
// - will replace trackAnalytics when
//   everything goes server side
export const trackAnalyticsServer = (
  analyticsUuid: string,
  eventName: string,
  additionalProperties?: AnalyticsEventProperties,
  alterData = true,
): void => {
  const flatAdditionalProperties = additionalProperties
    ? flattenEventProperties(additionalProperties)
    : additionalProperties;

  const serverEventProps = additionalServerEventProps();

  const properties: Record<string, any> = {
    page: window.location.pathname,
    fromWeb: true,
    ...(alterData ? flatAdditionalProperties : additionalProperties),
    environment: project.customEnv,
    ...serverEventProps,
    time: Date.now(),
  };
  if (!disableAnalytics && analyticsUuid != null) {
    trackUserBiEvent(analyticsUuid, eventName, properties);
  }
  logTrace("Analytics/a", { eventName, properties });
};

export const trackAnalytics = (
  eventName: string,
  actionType: string,
  additionalProperties?: AnalyticsEventProperties,
  alterData = true,
): void => {
  // --- This is different to the current implementation on the FE ---
  // Before flattening we prefix properties with their section so they do
  // not need to be globally unique.
  // E.G
  // {
  //   module { id: 1, name: 'My Module'},
  //   exercise: { id: 2, name: 'my exercise' }
  // }
  // Becomes:
  // {
  //   module.id: 1,
  //   module.name: 'My Module'
  //   exercise.id: 2,
  //   exercise.name: 'my exercise'
  // }

  const flatAdditionalProperties = additionalProperties
    ? flattenEventProperties(additionalProperties)
    : additionalProperties;
  const properties = {
    action: actionType,
    page: additionalProperties?.page ?? window.location.pathname,
    ...(alterData ? flatAdditionalProperties : additionalProperties),
    fromWeb: true,
    environment: project.customEnv,
  };
  if (!disableAnalytics) {
    Mixpanel.track(eventName, properties);
  }
  if (!disableAnalytics) {
    cioTrackAnalytics(eventName, {
      ...properties,
    });
    GTag("event", eventName);
  } else {
    console.log("CIO ANALYTICS----", eventName, {
      ...properties,
    });
  }
  logTrace("Analytics", { eventName, properties });
};
function additionalServerEventProps() {
  const referringDomainMatches = document.referrer.match(
    /^https?:\/\/(?:www\.)?([^/]+)/i,
  );
  const referringDomain = referringDomainMatches
    ? referringDomainMatches[0]
    : "$direct";
  return {
    ["$screen_height"]: window.screen.height, // numeric
    ["$screen_width"]: window.screen.width, // numeric
    ["$referrer"]: document.referrer.length ? document.referrer : "$direct",
    ["$referring_domain"]: referringDomain,
    ["$current_url"]: window.location.href,
  };
}

export const identify = (uuid: string): void => {
  if (!disableAnalytics) {
    Mixpanel.identify(uuid);
  }
  Sentry.setUser({ id: uuid });
};

function convertFeatureToAnalyticsProperties(
  key: string,
  feature: IFlagsmithFeature,
): Record<string, any> {
  if (feature.value != null && feature.value !== "") {
    return {
      [`flag__${key}`]: feature.enabled,
      [`flag__${key}_value`]: feature.value,
    };
  }

  return {
    [`flag__${key}`]: feature.enabled,
  };
}

const utmKeys = [
  "utm_source",
  "utm_medium",
  "utm_campaign",
  "utm_content",
  "utm_term",
  "utm_matchtype",
  "dclid",
  "fbclid",
  "gclid",
  "ko_click_id",
  "li_fat_id",
  "msclkid",
  "ttclid",
  "twclid",
  "wbraid",
];
export const getAdMetrics = () => {
  // get the utm parameters and store in state
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const utmData: Record<string, string> = {};
  utmKeys.map((key) => {
    utmData[key] = params.get(key)!; // needs to only send those that exist :/
  });
  return noEmptyAnalyticProps(utmData);
};

export function noEmptyAnalyticProps(props: Record<string, any>) {
  const notEmptyProps: Record<string, any> = {};
  Object.keys(props).forEach((key) => {
    if (props[key]) {
      notEmptyProps[key] = props[key]!;
    }
  });
  return notEmptyProps;
}
