import { ReactElement, useEffect, useState } from "react";

import AccountNavBar from "./AccountNavBar.tsx";
import utils from "../../utils";

type NavigationType = "Standard" | "CancelationFlow" | "None";

const SHOW_NAV_FOR = new Set(["clinic", "account"]);
const HIDE_NAV_FOR_PATHS = new Set(["/clinic/payment"]);

const NavBar = (): ReactElement => {
  const locationRoot = location.pathname.split("/")[1];

  const [currentNavigationType, setCurrentNavigationType] =
    useState<NavigationType>("None");

  const showNavBar =
    !utils.isUserOnApp() &&
    locationRoot != null &&
    SHOW_NAV_FOR.has(locationRoot) &&
    !HIDE_NAV_FOR_PATHS.has(window.location.pathname);

  useEffect(() => {
    const navType = getNavigationType();
    setCurrentNavigationType(navType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getNavigationType = (): NavigationType => {
    if (!showNavBar) return "None";
    return "Standard";
  };

  switch (currentNavigationType) {
    case "Standard":
      return <AccountNavBar />;
    default:
    case "None":
      return <></>;
  }
};

export default NavBar;
