import {
  BREAKPOINT_WIDTH,
  MOBILE_BREAKPOINT,
} from "../../constants/pageLayout";
import React, { ButtonHTMLAttributes, DetailedHTMLProps } from "react";

import { Substitute } from "styled-components/dist/types";
import styled from "styled-components";

type FullWidthButtonProps = Substitute<
  DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
  {
    /* add props here */
    backgroundColor?: string;
    textColor?: string;
    fontSize?: string;
  }
> & { as?: string; href?: string };

export const FullWidthButton = React.forwardRef(function Button(
  props: FullWidthButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement>,
) {
  return (
    <FullWidthButtonT
      ref={ref}
      {...props}
      $fontSize={props.fontSize ?? ""}
      $backgroundColor={props.backgroundColor ?? ""}
      $textColor={props.textColor ?? ""}
    />
  );
});

export const FullWidthButtonT = styled.button<{
  /* add props here */
  $backgroundColor?: string;
  $textColor?: string;
  $fontSize?: string;
}>`
  width: 100%;
  max-width: ${BREAKPOINT_WIDTH};
  min-height: 64px !important;
  border: none;
  border-radius: 10px;
  background-color: ${(props) =>
    props.$backgroundColor ||
    props.theme.colors.neuronWeb.brandSecondarySapphire};
  cursor: pointer;

  font-family: Poppins, sans-serif;
  font-size: ${(props) => props.$fontSize || "18px"};
  font-weight: 500;
  color: ${(props) =>
    props.$textColor || props.theme.colors.neuronWeb.neutralWhite};

  align-self: center;

  padding: ${(props) => props.theme.spacing.sm};

  &:hover {
    background-color: ${(props) =>
      props.$backgroundColor ||
      props.theme.colors.marketingDesignSystem.ziggurat};
  }

  ${MOBILE_BREAKPOINT} {
    &:hover {
      background-color: ${(props) =>
        props.$backgroundColor ||
        props.theme.colors.neuronWeb.brandSecondarySapphire};
    }
  }

  &:focus {
    background-color: ${(props) =>
      props.$backgroundColor ||
      props.theme.colors.marketingDesignSystem.ziggurat};
  }

  &:active {
    background-color: ${(props) =>
      props.$backgroundColor ||
      props.theme.colors.marketingDesignSystem.ziggurat};
  }

  &:disabled {
    background-color: ${(props) =>
      props.theme.colors.marketingDesignSystem.powderBlue};
  }

  align-items: center;
  justify-content: center;
  display: flex;
`;

export const FullWidthWhiteButton = React.forwardRef(function Button(
  props: FullWidthButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement>,
) {
  return <FullWidthWhiteButtonT ref={ref} {...props} />;
});

export const FullWidthWhiteButtonT = styled.button<{
  /* add props here */
}>`
  width: 100%;
  max-width: ${BREAKPOINT_WIDTH};
  min-height: 64px !important;
  border: none;
  border-radius: 10px;
  background-color: #ffffff;
  cursor: pointer;

  font-family: Poppins, sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.neuronWeb.brandSecondarySapphire};

  align-self: center;

  padding: ${(props) => props.theme.spacing.sm};

  &:hover {
    background-color: ${(props) =>
      props.theme.colors.marketingDesignSystem.ziggurat};
  }

  ${MOBILE_BREAKPOINT} {
    &:hover {
      background-color: ${(props) =>
        props.theme.colors.neuronWeb.brandSecondarySapphire};
    }
  }

  &:focus {
    background-color: ${(props) =>
      props.theme.colors.marketingDesignSystem.ziggurat};
  }

  &:disabled {
    background-color: ${(props) =>
      props.theme.colors.marketingDesignSystem.powderBlue};
  }

  align-items: center;
  justify-content: center;
  display: flex;
`;
