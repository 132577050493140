import { useEffect } from "react";
import useTrackExperimentViewed from "./useTrackExperimentViewed";

export const useFlagsProviderABTest = () => {
  const trackExperimentViewed = useTrackExperimentViewed();

  useEffect(() => {
    // look for the fp query param
    // this signifies that the user has used an experiment-enabled Unbounce URL
    const params = new URLSearchParams(window.location.search);
    const pValue = params.get("fp") || "";

    // if the query param is present, track the chosen flag provider
    if (pValue) {
      const map = { fs: "flagsmith", gb: "growthbook" };
      const selectedFlagsProvider = (map as any)[pValue] || "unknown";
      trackExperimentViewed("flags-ab-test" as any, selectedFlagsProvider);
    }
  }, [trackExperimentViewed]);
};
