import { useEffect, useState } from "react";

import { useServerSideAnalytics } from "./useServerSideAnalytics";

export default function useFirstQuizLoad() {
  const firstLoadEventName = "Quiz Initial Load";
  // if the app is loading for the first time,
  // send the firstLoadEventName event

  const serverSideEvent = useServerSideAnalytics();

  const firstLoadDoneLocalStorage =
    window.localStorage.getItem("firstLoadDone");

  const [firstLoadDone, setFirstLoadDone] = useState(
    firstLoadDoneLocalStorage ? true : false,
  );

  useEffect(() => {
    if (!firstLoadDone) {
      window.localStorage.setItem("firstLoadDone", "true");
      setFirstLoadDone(true);
      serverSideEvent(firstLoadEventName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstLoadDone, serverSideEvent]);
}
