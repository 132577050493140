import { FlagId } from "../constants/flags.ts";
import project from "../project.ts";
import { useCallback } from "react";
import { useServerSideAnalytics } from "./useServerSideAnalytics.ts";

export default function useTrackExperimentViewed() {
  const trackEvent = useServerSideAnalytics();

  return useCallback(
    (experiment: FlagId, variant: string | undefined) => {
      if (project.customEnv !== "production") {
        console.log("Experiment Viewed", {
          experiment,
          ...(variant ? { variant } : {}),
        });
      }
      trackEvent("Experiment Viewed", {
        experiment,
        ...(variant ? { variant } : {}),
      });
    },
    [trackEvent],
  );
}
