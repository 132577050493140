import Loader from "react-loader-spinner";

type LoadingRadialProps = {
  inline?: boolean;
  color?: string;
};

function LoadingRadial({ color, inline }: LoadingRadialProps) {
  if (!inline) {
    return (
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <Loader
          type="Rings"
          color={color || "#000000"}
          height={80}
          width={80}
        />
      </div>
    );
  } else {
    return (
      <div className="inline-block">
        <Loader
          type="Rings"
          color={color || "#000000"}
          height={80}
          width={80}
        />
      </div>
    );
  }
}

export default LoadingRadial;
