import {
  DiscoveredInflowOptionItem,
  RemoteConfigData,
} from "../models/remoteConfig";
import { UseQueryResult, useQuery } from "@tanstack/react-query";

import http from "../utils/http";

export default function useRemoteConfig(): UseQueryResult<RemoteConfigData> & {
  generalSettings: Record<string, string> | undefined;
  discoveredInflowOptions: DiscoveredInflowOptionItem[] | null;
} {
  const result = useQuery({
    queryKey: ["remote-config"],
    queryFn: async () =>
      http()
        .get("/app-config?fromWeb=true")
        .then((res) => res.data as RemoteConfigData),
    staleTime: 1000 * 60 * 5,
  });

  return {
    ...result,
    generalSettings: result.data?.generalSettings,
    discoveredInflowOptions: result.data?.discoveredInflowOptions || null,
  };
}
