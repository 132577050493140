import {
  AnalyticsEventProperties,
  trackAnalyticsServer,
} from "../utils/analytics.ts";

import project from "../project.ts";
import { selectAnalyticsUuid } from "../selectors/analytics.ts";
import { useCallback } from "react";
import { useSelector } from "react-redux";

export function useServerSideAnalytics() {
  const analyticsUuid = useSelector(selectAnalyticsUuid);

  return useCallback(
    (eventName: string, properties?: AnalyticsEventProperties) => {
      if (project.customEnv !== "production") {
        console.log("TRACKING/ServerSide", eventName, properties);
      }
      if (analyticsUuid) {
        trackAnalyticsServer(analyticsUuid, eventName, properties, undefined);
      }
    },
    [analyticsUuid],
  );
}
