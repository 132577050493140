import {
  AppAction,
  FIRST_NAME_CHANGED,
  LOGIN_SUCCESS,
  LOGOUT_CLICKED,
  QUIZ_EMAIL_ENTERED,
  REGISTER_SUCCESS,
  USER_EMAIL_CHANGED,
} from "../constants/actions";

import isBase64 from "../utils/isBase64";
import utils from "../utils/index";

export type UserEmailSource =
  | "QUIZ"
  | "QUERY"
  | "STORED_GO_BACK"
  | "STRIPE_REDIRECT";

type EmailState = Partial<{
  email: string;
  emailSource: UserEmailSource;
}>;

export type UserState = {
  token: string | null;
  firstName?: string;
} & EmailState;

function initialTokenState(): string | null {
  if (window.localStorage.getItem("token")) {
    return window.localStorage.getItem("token");
  }
  const searchParams = new URLSearchParams(window.location.search);
  let urlToken = searchParams.get("token");
  // decode the token if it's encoded already
  // length check prevents raw token being parsed
  if (urlToken && isBase64(urlToken)) {
    urlToken = utils.decodeToken(urlToken);
    return urlToken;
  } else {
    // else it's not a valid token
    return null;
  }
}

function initialEmailState(): EmailState {
  const queryParams = new URLSearchParams(window.location.search);
  const isInStripeRedirect = queryParams.get("redirect_status") != null;

  if (isInStripeRedirect) {
    const stripeRedirectEmail = queryParams.get("email");

    if (stripeRedirectEmail) {
      return {
        email: stripeRedirectEmail,
        emailSource: "STRIPE_REDIRECT",
      };
    }
  }

  // we sometimes store the email using the goBack handler (from the CheckoutPaymentPage)
  const storedCheckoutEmail = window.localStorage.getItem("checkout-email");
  if (storedCheckoutEmail) {
    return {
      email: storedCheckoutEmail,
      emailSource: "STORED_GO_BACK",
    };
  }

  const queryParamsEmail = queryParams.get("userEmail");
  if (queryParamsEmail) {
    return {
      email: queryParamsEmail,
      emailSource: "QUERY",
    };
  }

  return {};
}

const initialState: UserState = {
  token: initialTokenState(),
  ...initialEmailState(),
  firstName: "",
};

export default (
  state: UserState = initialState,
  action: AppAction,
): UserState => {
  switch (action.type) {
    case LOGOUT_CLICKED: {
      return {
        ...state,
        token: null,
      };
    }
    case LOGIN_SUCCESS: {
      return {
        ...state,
        token: action.token,
      };
    }
    case REGISTER_SUCCESS: {
      return {
        ...state,
        token: action.token,
      };
    }
    case QUIZ_EMAIL_ENTERED: {
      return {
        ...state,
        emailSource: "QUIZ",
      };
    }
    case USER_EMAIL_CHANGED: {
      return {
        ...state,
        email: action.email,
      };
    }
    case FIRST_NAME_CHANGED: {
      return {
        ...state,
        firstName: action.firstName,
      };
    }
    default: {
      return state;
    }
  }
};
