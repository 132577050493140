import { z } from "zod";

const configSchema = z.object({
  apiBase: z.string(),
  headerKey: z.string(),
  mixpanelKey: z.string(),
  customEnv: z.string(),
  sentryDSN: z.string(),
  tracePropagationTargetsRegex: z.string(),
  typeformId: z.string().optional(),
  typeformPlatform: z.string().optional(),
  stripePublishableKey: z.string(),
  flagsmithKey: z.string(),
  growthbookClientKey: z.string(),
  growthbookDecryptionKey: z.string(),
  s3AssetsBaseUrl: z.string(),
  googleAnalyticsTag: z.string(),
  revenuecatStripeAppPublicApiKey: z.string(),
  testsRunning: z.string(),
  version: z.string(),
});

const config = {
  apiBase: import.meta.env.VITE_API_BASE_URL,
  headerKey: import.meta.env.VITE_HEADER_KEY,
  mixpanelKey: import.meta.env.VITE_MIXPANEL_KEY,
  customEnv: import.meta.env.VITE_CUSTOM_ENV,
  sentryDSN: import.meta.env.VITE_SENTRY_DSN,
  tracePropagationTargetsRegex: import.meta.env
    .VITE_TRACE_PROPAGATION_TARGETS_REGEX,
  typeformId: import.meta.env.VITE_TYPEFORM_WIDGET_ID || "",
  typeformPlatform: import.meta.env.VITE_TYPEFORM_PLATFORM || "",
  stripePublishableKey: import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY,
  flagsmithKey: import.meta.env.VITE_FLAGSMITH_KEY,
  growthbookClientKey: import.meta.env.VITE_GROWTHBOOK_CLIENT_KEY,
  growthbookDecryptionKey: import.meta.env.VITE_GROWTHBOOK_DECRYPTION_KEY,
  s3AssetsBaseUrl: import.meta.env.VITE_S3_ASSETS_BASE_URL,
  googleAnalyticsTag: import.meta.env.VITE_UA_TAG,
  revenuecatStripeAppPublicApiKey: import.meta.env
    .VITE_REVENUECAT_STRIPE_APP_PUBLIC_API_KEY,
  testsRunning: import.meta.env.VITE_TESTS_RUNNING,
  version: "1.1.5",
};

const validatedConfig = configSchema.safeParse(config);

if (!validatedConfig.success) {
  throw new Error("Invalid ENV config: " + validatedConfig.error.message);
}

export default validatedConfig.data;
