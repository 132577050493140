import AccountIcon from "../../pages/Account/components/AccountIcon.tsx";
import { ReactComponent as InflowLogoFull } from "../../icons/InflowLogoFull.svg";
import { LOGOUT_CLICKED } from "../../constants/actions.ts";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useHistory } from "../../custom-hooks/useHistory.ts";
import useUserData from "../../custom-hooks/useUserData.ts";
import utils from "../../utils";

export const NavBarWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 875px;
  height: 60px;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  gap: 10px;
`;

const BackArrowWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: left;
  flex-grow: initial;
  width: 70px;
  padding-left: 10px;
`;

const BackArrowContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  width: 10px;
  transform: rotate(180deg);

  & svg > path {
    stroke: #242236;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  flex-grow: 1;
`;

const LogoutContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  flex-grow: initial;
  width: 70px;
  font-weight: 600;
  padding-right: 10px;
  color: #086375;
`;

const AccountNavBar = () => {
  const dispatch = useDispatch();
  const { isLoggedIn } = useUserData();
  const history = useHistory();

  const handleLogout = () => {
    dispatch({ type: LOGOUT_CLICKED });
    void utils.logOut();
  };

  const showBackArrow = () => {
    switch (history.location.pathname) {
      case "/account":
        return false;
      default:
        return true;
    }
  };

  return (
    <NavBarWrapper>
      <BackArrowWrapper>
        {showBackArrow() && (
          <BackArrowContainer onClick={() => history.goBack()}>
            <AccountIcon iconName="Chevron" />
          </BackArrowContainer>
        )}
      </BackArrowWrapper>
      <LogoContainer>
        <InflowLogoFull className="w-28" />
      </LogoContainer>
      <LogoutContainer>
        {isLoggedIn && (
          <button data-testid="logoutButton" onClick={handleLogout}>
            Log out
          </button>
        )}
      </LogoutContainer>
    </NavBarWrapper>
  );
};

export default AccountNavBar;
